<template>
    <div class="pageBox">
        <div class="topBox">
            <el-button  color="#803a15" dark @click="oper(0,null)">
                <svg-icon icon-class="plus"/>&nbsp;&nbsp;添加
            </el-button>
        </div>
        <div class="boxTable">
            <div class="leftBox">
                <div class="title">角色列表</div>
                <div class="boxP">
                    <el-table 
                    v-loading="loading"
                    @current-change="rowChange"
                    size="large" 
                    :data="tableData"
                    :highlight-current-row="true" 
                    style="width: 100%">
                        <el-table-column prop="roleName" label="角色名称" align="center" />
                        <el-table-column prop="description" label="描述" align="center" />
                        <el-table-column label="操作" width="240" align="center">
                            <template #default="scope">
                                <el-button type="success" size="small" plain @click="oper(1,scope.row)">编辑</el-button>
                                <el-button type="danger" size="small" @click="del(scope.row.id)" plain>删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination 
                    background 
                    :page-size="query.size"
                    :current-page="query.page"
                    layout="total, prev, pager, next"
                    @current-change="pageChange" 
                    :total="total" />
                </div>
            </div>
            <div class="rightBox">
                <div class="title areaTitle">
                    <div>菜单分配</div>
                    <div>
                        <el-button @click="saveLinkPermission" :disabled="roleId==null"  color="#803a15" dark size="small">
                            <svg-icon icon-class="check"/>&nbsp;保存
                        </el-button>
                    </div>
                </div>
                <div class="boxP">
                    <el-tree
                        ref="treeMenu"
                        :data="treedata" 
                        :default-checked-keys="currentMenuIds"
                        show-checkbox
                        node-key="id"
                        @check="checkChange"
                        :check-strictly="true"
                        :props="MenuDefaultProps">
                    </el-tree>
                </div>
            </div>
        </div>
        <oper-form ref="operForm" @OK="CX"/>
    </div>
</template>
<script>
import operForm from './Modules/oper.vue'
import { getRoles, delRole,  listMenu, bindlistMenu } from '@/api/role.js'
export default {
    components:{
        operForm
    },
    data(){
        return{
            query:{
                page:1,
                size:10,
            },
            total:0,
            tableData:[],
            loading:false,
            roleId:null,

            treedata:[],
            MenuDefaultProps:{
                children: 'children',
                label: 'title',
            },
            currentMenuIds:[],
        }
    },
    mounted(){
        this.CX(0)
        this.GetlistPermission()
    },
    methods:{
        pageChange(page){
            this.query.page = page
            this.CX(1)
        },
        GetlistPermission(){
            listMenu().then(r=>{
                this.treedata = r.data
            })
        },
        CX(type){
            if(type == 0){
                this.query.page = 1
            }
            this.loading = true
            getRoles(this.query).then(r=>{
                this.loading = false
                this.tableData = r.data.data
                this.total = r.data.pageInfo.total
            })
        },
        rowChange(row){
            if (row) {
                this.$refs.treeMenu.setCheckedKeys([])
                this.roleId = row.id
                this.currentMenuIds = row.menuids
            }
        },
        oper(type,data){
            this.$refs.operForm.open(type,data)
        },
        del(id){
            this.$confirm(
                '此操作将永久删除该信息，是否继续?',
                'Warning',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            ).then(() => {
               delRole(id).then(r=>{
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    // this.query.page = 1
                    this.GetAllRole()
                })
            })
            .catch(() => {
                
            })
        },
        saveLinkPermission(){
            let query = {
                roleId:this.roleId,
                menuids:this.currentMenuIds.join(',')
            }
            bindlistMenu(query).then(r=>{
                this.$message.success("菜单分配成功")
                this.CX(1)
            })
        },
        checkChange(menu){
            let flag = this.currentMenuIds.includes(menu.id)
            if(flag){
                const Index = this.currentMenuIds.indexOf(menu.id)
                if (Index !== -1) {
                    this.currentMenuIds.splice(Index, 1)
                } 
            }else{
                this.currentMenuIds.push(menu.id)
            }
            this.$refs.treeMenu.setCheckedKeys(this.currentMenuIds)
        },
    }
}
</script>
<style lang="scss" scoped>
.topBox{
    width: 100%;
    margin-bottom: 20px;
}
.boxTable{
    width: 100%;
    display: flex;
    justify-content: space-between;
    .title{
        line-height: 60px;
        border-bottom: 1px solid #CFCFCF;
        color: #854933;
        font-size: 20px;
        padding: 0px 20px;
        box-sizing: border-box;
    }
    .areaTitle{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .boxP{
        padding: 20px;
        width: 100%;
        box-sizing: border-box;
    }
    .leftBox{
        width: calc(100% - 420px);
        background: white;
        border-radius: 5px;
    }
    .rightBox{
        width: 400px;
        background: white;
        border-radius: 5px;
    }
}
.pageBox{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
}

.el-pagination{
    margin-top: 20px;
    justify-content: center;
}
/deep/.el-pagination.is-background .el-pager li.is-active{
    background-color: #803a15;
}
</style>